.view-page-content-wrapper {
  .btn-group > .btn {
    flex: none;
  }
  .view-page-header {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .view-page-tab-style-wrapper {
    .nav-tabs .nav-item .nav-link {
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
      font-size: 11px !important;
      padding: 3px 8px !important;
    }
  }
  .simplebar-view-page-style {
    height: 53vh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  .dropdown-menu-style {
    .dropdown-menu {
      padding: 7.5px !important;
    }
    .dropdown-item {
      padding: 3.75px 15px !important;
      font-size: 12.5px !important;
    }
  }
  .voucher-inquiry-view-wrapper {
    .voucher-inquiry-heading {
      font-size: 14px !important;
      font-weight: 600;
    }
    .badge {
      border-radius: 3px !important;
      -webkit-border-radius: 3px !important;
      -moz-border-radius: 3px !important;
      -ms-border-radius: 3px !important;
      -o-border-radius: 3px !important;
    }
  }
  .tab-view-heading {
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .view-page-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .view-page-payment-message-wrapper {
    padding: 3.75px 15px;
    margin: 7.5px 0px;
  }
}

.simplebar-style-list-page-without-pagination {
  height: 78.5vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.simplebar-style-list-page {
  max-height: 74vh !important;
  min-height: 74vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.attachments-styles {
  .attachments-card {
    width: 80%;
    height: 100%;
    border: 1px solid rgb(216, 215, 215);
    border-radius: 10px;
    position: relative;
    .png-images {
      position: absolute;
      top: 0%;
    }
    .attachments-hover-styles {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid transparent;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      opacity: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      .download-icon:hover {
        color: green !important;
      }
      .delete-icon:hover {
        color: red !important;
      }
    }
    .attachments-hover-styles:hover {
      opacity: 1;
      transition: 0.5s ease-in;
    }
  }
}

.file-upload-wrapper {
  height: 15rem !important;
  width: 15rem !important;
  border: 1px dashed black;
}

.three-column-layout-list-button {
  box-shadow: none !important;
  background-color: white !important;
  border: 1px solid rgb(209, 208, 208) !important;
  padding: 4px !important;
}

//Mobile view
@media screen and (min-width: 320px) and (max-width: 425px) {
  .view-page-contents {
    align-items: flex-start !important;
    flex-direction: column !important;
  }
  .view-page-payment-message-wrapper {
    padding: 0px !important;
    margin: 0px !important;
  }
  .simplebar-style-list-page {
    max-height: 71vh !important;
    min-height: 71vh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}
