.simplebar-style-with-pagination {
  height: 74.9vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-and-filter-on {
  height: 64.8vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-and-filter-on-with-two-row {
  height: 59vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-for-tab {
  height: 70.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-and-filter-on-for-tab {
  height: 62vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-for-tab-in-project {
  height: 61.7vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-for-tab-in-project-filter-on {
  height: 53.2vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-and-filter-on-with-three-row {
  height: 51.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-with-pagination-and-filter-on-with-three-row-for-ticket {
  height: 50.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

//Related to task in project view
.simplebar-style-with-pagination-for-task-in-project-tab {
  height: 62vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.simplebar-style-with-pagination-for-task-in-project-tab-filter-on {
  height: 45.6vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

//Related to task in sprint view
.simplebar-style-with-pagination-for-task-in-sprint-tab {
  height: 72vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.simplebar-style-with-pagination-for-task-in-sprint-tab-filter-on {
  height: 54.7vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

// Related to task in employee view
.simplebar-style-with-pagination-for-task-in-employee-tab {
  height: 70vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.simplebar-style-with-pagination-for-task-in-employee-tab-filter-on {
  height: 46.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

// Related to task in account view
.simplebar-style-with-pagination-for-task-in-account-tab {
  height: 71.3vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.simplebar-style-with-pagination-for-task-in-account-tab-filter-on {
  height: 46.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

// Related to report table view
.simplebar-style-with-pagination-for-report-table-filter-on {
  height: 64.8vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.simplebar-style-with-pagination-for-report-table-filter-on-with-two-row {
  height: 61.8vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}
