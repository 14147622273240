.project-custom-profile-image {
  padding-top: 2vh !important;
  padding-bottom: 1vh !important;
}
.project-custom-profile-section {
  background: linear-gradient(to bottom, #2b3c5c 0%, white 50%);
}
.project-custom-close-button {
  padding-top: 10px;
  padding-right: 10px;
}

.custom-profile-tabs {
  background: linear-gradient(to bottom, #fff 0%, #6352c126 60%);
}

.custom-profile-tabs > .nav-item > .nav-link.active {
  background-color: #8982a5;
  color: white;
}

.custom-profile-tabs > .nav-item > .nav-link {
  color: #000;
}
.card-user-profile {
  border: none !important;
  box-shadow: none !important;
}
