.react-date-picker__inputGroup__input {
  height: 50% !important;
}
.custome-date-picker {
  width: 100%;
  color: black !important;
  .react-date-picker__wrapper {
    border: 1px solid #b5b5b5;
    font-size: 12px;
    font-weight: 400 !important;
    padding: 0.3125rem 1rem;
  }
  input {
    background-color: transparent !important;
  }
}
.custome-date-picker.is-invalid {
  .react-date-picker__wrapper {
    border-color: #dc3545 !important;
  }
}
.custome-date-picker-calendar {
  color: #000 !important;
  width: 235px;
  box-shadow: 1px 2px 5px #c9c9c9;
  font-size: 0.7rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  border-radius: 0px !important;

  .react-calendar__tile {
    line-height: 10px;
    font-weight: 500;
    font-size: 0.65rem;
  }

  .react-calendar__tile--now {
    background-color: var(--falcon-200) !important;
    color: #212529 !important;
    font-weight: bold;
    border-radius: 0px !important;
    padding: 5px 3px;
    &:hover {
      background-color: #a5a7bd !important;
      color: #fff !important;
    }
  }
  .react-calendar__tile--active {
    background-color: #616491 !important;
    color: #fff !important;
  }
  .react-calendar__navigation {
    button {
      min-width: 27px;
    }
  }
}

//app-date-time-picker
.custom-date-time-picker {
  width: 100% !important;
  .react-datetime-picker__wrapper {
    height: 34px !important;
    border: 1px solid #b5b5b5;
    font-size: 12px;
    font-weight: 400 !important;
    padding: 0.3125rem 1rem;
  }
}

.custom-date-time-picker-container {
  .custom-date-time-picker-calendar {
    background-color: white !important;
    border-radius: 0px !important;
    box-shadow: 1px 2px 5px #c9c9c9 !important;
    .react-datepicker__current-month,
    .react-datepicker__current-year {
      display: none;
    }
    .react-datepicker__month-read-view {
      visibility: visible !important;
    }
    .react-datepicker__year-read-view--selected-year {
      visibility: visible !important;
    }
    .react-datepicker__header__dropdown {
      padding: 7px !important;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      border: 1px solid #b5b5b5 !important;
      height: 25px !important;
      padding: 0px 4px 0px 4px !important;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select:focus-visible {
      outline: none !important;
    }
    .react-datepicker__navigation {
      top: 13px !important;
    }
    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__time-list-item,
    .react-datepicker-time__header {
      color: #212529 !important;
    }
    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected {
      color: #fff !important;
      background-color: #696c96 !important;
      border-radius: 0px !important;
    }
    .react-datepicker__day--today {
      border-radius: 0px !important;
    }
  }
  input.form-control::placeholder {
    font-size: 12px;
    color: grey !important;
  }

  //for react-date-time-picker adjustment if today button is visible
  // .react-datepicker-popper {
  //   left: 0px !important;
  // }
  // .react-datepicker__time-list {
  //   height: 249px !important;
  // }
  // .react-datepicker__time-container--with-today-button {
  //   border: 0 !important;
  // }
  // .react-datepicker__time-box {
  //   border: 1px solid var(--falcon-300) !important;
  //   border-left: 0px !important;
  //   border-radius: 0px !important;
  //   box-shadow: 1px 2px 5px #c9c9c9 !important;
  // }
  // .react-datepicker__header--time {
  //   border-right: 1px solid var(--falcon-300) !important;
  //   border-top: 0.1px solid var(--falcon-300) !important;
  //   border-bottom: 0px !important;
  //   border-left: 0px !important;
  //   border-radius: 0px !important;
  //   box-shadow: 1px 2px 5px #c9c9c9 !important;
  // }
}
