.setInnerHtml {
  text-align: justify;
  p {
    margin: 0px !important;
  }
  img {
    width: 200px;
    height: 150px;
  }
  word-wrap: break-word !important;
}
