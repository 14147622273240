.git-graph-container-div {
  position: relative;
  padding: 5px 10px 5px 50px;
}
.git-graph-container-div,
.git-graph-container-div * {
  box-sizing: border-box;
}

.git-graph-container-div::before {
  content: "";
  width: 2px;
  background-color: #2c7be5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  transition: all 0.2s ease-in-out;
}

.git-graph-container-div:first-child::before {
  top: 50%;
}

.git-graph-container-div:last-child::before {
  bottom: 50%;
}

.git-graph-container-div.expanded:first-child::before {
  top: 25%;
}

.git-graph-container-div.expanded:last-child::before {
  bottom: 75%;
}

.git-graph-container-wrapper {
  padding: 10px;
  background-color: #ebebeb;
  position: relative;
  border-radius: 6px;
  width: 100%;
}

.git-graph-container-wrapper::before {
  content: "";
  border: 10px solid transparent;
  border-right-color: #ebebeb;
  border-left: 0;
  position: absolute;
  top: 20%;
  left: -10px;
}

.git-graph-container-wrapper::after {
  content: "";
  background: #2c7be5;
  border: 4px solid #2c7be5;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  position: absolute;
  top: 20%;
  left: -44px;
}

.git-graph-accordion-content {
  border-top: 1px solid #acacac;
}

//////////////////////////////////////////////////////////////////////////////////////

.git-graph-container-div-skelton {
  position: relative;
  padding: 5px 10px 5px 50px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    animation: pulse 1.5s infinite ease-in-out;
  }
}

.git-graph-container-div-skelton,
.git-graph-container-div-skelton * {
  box-sizing: border-box;
}

.git-graph-container-div-skelton::before {
  content: "";
  width: 2px;
  background-color: #e9e9e9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
}

.git-graph-container-div-skelton:last-child::before {
  bottom: 50%;
}

.git-graph-container-div-skelton:first-child::before {
  top: 50%;
}

.git-graph-container-wrapper-skelton {
  padding: 10px;
  background-color: #e9e9e9;
  position: relative;
  border-radius: 6px;
  width: 100%;
}

.git-graph-container-wrapper-skelton::before {
  content: "";
  border: 10px solid transparent;
  border-right-color: #e9e9e9;
  border-left: 0;
  position: absolute;
  top: 20%;
  left: -10px;
}

.git-graph-container-wrapper-skelton::after {
  content: "";
  background: #e9e9e9;
  border: 4px solid #e9e9e9;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  position: absolute;
  top: 20%;
  left: -44px;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}