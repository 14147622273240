.modal-shake {
    animation: shake 0.8s cubic-bezier(0.28, 0.04, 0.65, 0.97) both;
    transform: translate3d(0, 0, 0);
    pointer-events: none;
  }
  
  @keyframes shake {
    0%, 100% { transform: translate3d(0, 0, 0) rotate(0deg); }
    10% { transform: translate3d(-5px, -2px, 0) rotate(-3deg); }
    20% { transform: translate3d(5px, 2px, 0) rotate(3deg); }
    30% { transform: translate3d(-3px, -1px, 0) rotate(-2deg); }
    40% { transform: translate3d(3px, 1px, 0) rotate(2deg); }
    50% { transform: translate3d(-2px, -0.5px, 0) rotate(-1deg); }
    60% { transform: translate3d(2px, 0.5px, 0) rotate(1deg); }
    70% { transform: translate3d(-1px, -0.25px, 0) rotate(-0.5deg); }
    80% { transform: translate3d(1px, 0.25px, 0) rotate(0.5deg); }
  }