.task-overview-status-list-item {
  margin-left: 5px;
  width: 18vh;
  box-shadow: none !important;
}


.custom-button-task-status.active {
  margin-left: 5px !important;
}

.task-overview-status-icon {
  color: #dc0404c4 !important;
}
.task-overview-nav {
  background-color: #e9e9e9 !important;
}

.task-overview-content-tab {
  max-height: 83.1vh !important;
  overflow: auto;
}
