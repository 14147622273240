.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.preview-image {
  max-width: 80%;
  max-height: 80%;
  border: 2px solid #d6d6d6;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}
