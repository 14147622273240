.custom-digital-timer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.custom-digital-timer-day-wrapper {
  background-color: #2b3139 !important;
  color: white !important;
  .custom-digital-timer-day-header {
    color: white !important;
  }
}
.custom-digital-timer-button-wrapper {
  padding: 0.46rem 0.46rem 0.46rem 0.46rem !important;
}
