.ticket-custom-overview-card-header {
  border-color: var(--falcon-border-color);
  border-style: solid;
  border-width: 1px 1px 0px 1px;
}
.ticket-custom-tab-overview {
  height: 56.5vh;
  overflow: auto;
}
.ticket-status-open:hover {
  background-color: #76ccf4 !important;
  color: whitesmoke;
}
.ticket-status-new:hover {
  background-color: #a092fd9c !important;
  color: whitesmoke;
}

.ticket-status-pending:hover {
  background-color: #fdcd2ac2 !important;
  color: whitesmoke;
}

.ticket-status-awaiting_business_input:hover {
  background-color: #ffaa0bb0 !important ;
  color: whitesmoke;
}

.ticket-status-with_client_review:hover {
  background-color: #ffad6d !important;
  color: whitesmoke;
}
.ticket-status-defered:hover {
  background-color: #f78989 !important;
  color: whitesmoke;
}
.ticket-status-closed:hover {
  background-color: #6cd75d !important;
  color: whitesmoke;
}
