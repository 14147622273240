.fc-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.fc-prev-button,
.fc-next-button {
  background-color: #ffffff !important;
  border: 1px solid #b4b4b4 !important;
  color: #000000 !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.fc-today-button {
  background-color: #ffffff !important;
  border: 1px solid #b4b4b4 !important;
  color: #000000 !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  text-transform: capitalize !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.fc-myCustomButton-button {
    background-color: #ffffff !important;
    border: 1px solid #b4b4b4 !important;
    color: #2C7BE5 !important;
    border-radius: 5px !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
    text-transform: capitalize !important;
    width: max-content !important;
  }
