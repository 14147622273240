.react-datepicker__predefined-ranges {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  .list-group {
    div {
      &:hover {
        background-color: #c7d2f3;
      }
    }
  }
  .date-range-month {
    width: 100px;
    height: 50px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #c7d2f3;
    }
  }

  .active-month {
    background-color: #4c6099;
    color: #ffff;
  }

  .react-datepicker__ranges {
    max-height: 17rem;
    width: 15.5rem;
    overflow: hidden;
    overflow-y: scroll;
    color: black;
    background-color: white !important;
    border-right: 1px solid #dedede !important;
    border: none !important;

    .list-group-item {
      border: none !important;

      &:hover {
        background-color: #e1e2ea !important;
      }
    }

    .list-group-item.active {
      background-color: #696c96 !important;
      border: none !important;
      &:hover {
        background-color: #192e49 !important;
      }
    }
    .list-group-item:active {
      background-color: #696c96 !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(251, 251, 251) !important;
      border-radius: 10px !important;
    }

    &::-webkit-scrollbar {
      width: 7px !important;
      height: 10px !important;
      display: inline;
      unicode-bidi: bidi-override;
      margin-left: 20px;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: rgb(207, 227, 244);
    }

    &::-webkit-scrollbar-thumb {
      background: #bfd6f6 !important;
      border-radius: 10px !important;
      margin-left: 20px !important;
    }
  }

  .react-datepicker__navigation--previous {
    left: calc(7rem + 150px) !important;
  }
}

.react-datepicker__triangle {
  display: none !important;
}

.custom-date-range-picker {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .custom-date-range-picker-popper {
    z-index: 3 !important;
    transform: translate(17px, 135px) !important;
    .react-datepicker__day--today {
      color: #000 !important;
      background-color: #edf2f9 !important;
      border-radius: 0px !important;
    }
    .react-datepicker__day {
      color: #212529 !important;
    }
    .react-datepicker__day--range-start,
    .react-datepicker__day--selected,
    .react-datepicker__day--range-end {
      color: #fff !important;
      background-color: #696c96 !important;
      border-radius: 0px !important;
    }
    .list-group-item {
      color: #212529 !important;
    }
    .list-group-item.active {
      color: #fff !important;
    }
  }
}
