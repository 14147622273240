.pill-text-input {
    .pill-text-input-container {
    background-color: #ffffff;
      border-radius: 0px !important;
      border: 1px solid #b5b5b5;
      font-size: 12px;
      line-height: 20px !important;
      font-weight: 400 !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
    
    .pill-text-input-container:hover {
      border-color: #b3a7ee !important;
    }
  
    .pill-text-input-container:focus-within {
      box-shadow: none !important;
      border-color: #b3a7ee !important;
    }
  
    .pill-text-input-badge {
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: hsl(0, 0%, 90%);
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
    }
  
    .form-control {
      box-shadow: none !important;
      border: none !important;
      padding: 0 !important;
      width: 1px !important;
      // color: white !important; // this is for the automatic badge creation. its has been removed for testing
      text-shadow: none !important;
      caret-color: black !important;
    }
  }