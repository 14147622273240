.tile-heading {
  font-size: 15px !important;
}
.avatar-overlap {
  margin-right: -10px;
}
.numberstyle {
  margin-top: 4px;
  font-size: 11px !important;
  color: #8c857c;
  padding: 4px;
}
.no-default-arrow::after {
  display: none !important;
}
.circle-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;

  margin-right: 10px;
  display: inline-block;
}
.datecolor {
  color: #73a1e6;
}
.text-lightblue {
  color: #6698e3 !important;
}
.react-calendar__month-view__weekdays__weekday {
  background-color: #f9fafd;
  text-decoration: none !important;
  text-transform: capitalize;
  color: #616e80;
}
// .react-calendar__navigation {
//   display: none !important;
// }

.react-calendar__tile--active {
  border-radius: 50% !important;
  background-color: #407cde !important;
  max-width: 5%;
}
.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
.react-calendar__month-view__days__day--weekend:focus {
  color: white !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: rgb(209, 209, 209) !important;
}
.react-calendar__tile--now {
  border: 1px solid orange !important;
  background-color: white !important;
  border-radius: 50% !important;
  padding: 15px !important;
}
.borderbottomstyle {
  border-bottom: 1px solid red !important;
}
@media (max-width: 991.98px) {
  .overflow-visible-sm {
    overflow: visible !important;
  }
}