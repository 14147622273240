.settings-page-card-wrapper {
  max-height: 46vh;
  min-height: 23vh;
  .settings-card-header {
    margin: 0px !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 14px !important;
    color: black !important;
    font-weight: 500 !important;
  }
  .form-label {
    font-size: 12px !important;
    color: rgb(7, 7, 7) !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
  .finance-settings-label {
    .form-label {
      font-weight: 400 !important;
      padding: 6.7px !important;
      font-size: 11px !important;
      color: rgb(66, 66, 66) !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
  }
}

.settings-page-small-card-wrapper {
  max-height: 46vh;
  min-height: 16vh;
  .settings-card-header {
    margin: 0px !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 14px !important;
    color: black !important;
    font-weight: 500 !important;
  }
  .form-label {
    font-size: 12px !important;
    color: rgb(7, 7, 7) !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
  .finance-settings-label {
    .form-label {
      font-weight: 400 !important;
      padding: 6.7px !important;
      font-size: 11px !important;
      color: rgb(66, 66, 66) !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
  }
}

.settings-page-medium-card-wrapper {
  max-height: 46vh;
  min-height: 20vh;
  .settings-card-header {
    margin: 0px !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 14px !important;
    color: black !important;
    font-weight: 500 !important;
  }
  .form-label {
    font-size: 12px !important;
    color: rgb(7, 7, 7) !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
  .finance-settings-label {
    .form-label {
      font-weight: 400 !important;
      padding: 6.7px !important;
      font-size: 11px !important;
      color: rgb(66, 66, 66) !important;
      font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    }
  }
}

.sales-settings-page {
  min-height: 30vh;
  .settings-card-header {
    margin: 0px !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 14px !important;
    color: black !important;
    font-weight: 500 !important;
  }
  .form-label {
    font-size: 11px !important;
    color: rgb(7, 7, 7) !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.purchase-settings-page {
  min-height: 30vh;
  .settings-card-header {
    margin: 0px !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 14px !important;
    color: black !important;
    font-weight: 500 !important;
  }
  .form-label {
    font-size: 11px !important;
    color: rgb(7, 7, 7) !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.inventory-settings-page {
  min-height: 25vh;
  .settings-card-header {
    margin: 0px !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    font-size: 14px !important;
    color: black !important;
    font-weight: 500 !important;
  }
  .form-label {
    font-size: 11px !important;
    color: rgb(7, 7, 7) !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    font-weight: 500 !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.system-settings-navbar {
  .system-settings-navbar-item {
    color: rgb(41, 40, 40);
    font-size: 14px !important;
  }
}

.settings-wrapper {
  .dummy-image {
    height: 11rem;
    width: 10.5rem;
    border: 1px dashed black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-size: 10px !important;
      margin: 0px !important;
    }
  }
  .nav-tabs .nav-item .nav-link {
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 11px !important;
    padding-bottom: 3px !important;
  }
  .company-settings-tab-style {
    height: 59vh !important;
    overflow-y: scroll;
    overflow-x: none;
  }
  .input-group-text {
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    font-size: 10px !important;
  }
}

.settings-button-styles {
  padding: 5px 8px !important;
  color: white !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.settings-badge-style {
  margin-left: 5px !important;
  font-size: 11px !important;
  text-transform: uppercase;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
}

.company-select-width-styles {
  .css-b62m3t-container {
    width: 90% !important;
  }
}
