.attachmnets-container {
  height: 79vh !important;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  gap: 10px !important;
  .attachments-adding-container {
    height: 12rem;
    width: 12rem;
    border: 1px dashed rgb(216, 215, 215);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .adding-icon {
      cursor: pointer !important;
    }
    p {
      margin: 0px !important;
      font-size: x-small !important;
    }
  }
  .attachments-card {
    width: 80%;
    height: 100%;
    border: 1px solid rgb(216, 215, 215);
    border-radius: 10px;
    position: relative;
    .png-images {
      position: absolute;
      top: 0%;
      background-color: white;
    }
    .attachments-hover-styles {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid transparent;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      opacity: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      .download-icon:hover {
        color: green !important;
      }
      .delete-icon:hover {
        color: red !important;
      }
    }
    .attachments-hover-styles:hover {
      opacity: 1;
      transition: 0.5s ease-in;
    }
  }
}
