.loading-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1020;
    align-items: center;
    justify-content: center;
   display: flex;
   .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: spin 2s linear infinite; /* Add the animation here */
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
      
  }

  
