$md-screen: 768px;
$bg-color: #2e2e2e;
$bg-transparent: #000000bd;
.auth-screen-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $bg-color;
  padding: 15px;
  background-image: url(../images/auth-login-background-crm.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bg-transparent;
    top: 0;
    left: 0;
  }

  .brand-logo {
    position: relative;
    z-index: 1;
  }

  .form-container {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    transition: all ease 0.5s;
    position: relative;
    z-index: 1;

    input {
      box-shadow: none !important;
      outline: none !important;
      border: none !important;
      background-color: #fff !important;
      &::placeholder {
        color: #000 !important;
        opacity: 0.6;
      }
    }
  }

  .info {
    font-size: 0.75rem;
  }

  .mail-success {
    img {
      max-width: 200px;
      transition: transform ease-in-out 0.8s;
    }

    .title {
      font-size: 1.7rem;
    }
    &:hover {
      img {
        transform: scale(0.95);
      }
    }
  }

  .reset-title {
    font-size: 1.1rem !important;
  }
}
