$bg: white;
$color: #ef5350;
$size: 4rem;

.container-403 {
  background: $bg;
  text-align: center;
  .text-header-403 {
    font-size: 1.25rem;
    margin-top: 0.5rem;
    color: #263238;
    opacity: 1;
    transform: translateX(-0.1rem);
    // animation: fadeIn 1s forwards 1.5s;
  }
  .text-description-403 {
    margin: 0;
    margin-top: 0.5rem;
    color: #546e7a;
    opacity: 1;
    transform: translateX(-0.1rem);
    // animation: fadeIn 1s forwards 1.75s;
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(1rem);
    opacity: 0;
  }

  to {
    transform: translateY(0rem);
    opacity: 1;
  }
}
.forbidden-sign-403 {
  margin: auto;
  width: $size + ($size/6);
  height: $size + ($size/6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color;
  animation: grow 1s forwards;
}

@keyframes grow {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}

.forbidden-sign-403::before {
  position: absolute;
  background-color: $bg;
  border-radius: 50%;
  content: "";
  width: $size;
  height: $size;
  transform: scale(0);
  animation: grow2 0.5s forwards 0.5s;
}
@keyframes grow2 {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.forbidden-sign-403::after {
  content: "";
  z-index: 2;
  position: absolute;
  width: $size;
  height: $size/12;
  transform: scaley(0) rotateZ(0deg);
  background: $color;
  animation: grow3 0.5s forwards 1s;
}
@keyframes grow3 {
  from {
    transform: scaley(0) rotateZ(0deg);
  }

  to {
    transform: scaley(1) rotateZ(-45deg);
  }
}
