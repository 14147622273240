$ribbon-color: #E9E9E9;
$active-ribbon-color: #00D27A;
$text-color: #000000;
$active-text-color: #ffffff;
$ribbon-wrapper-color: #E9E9E9;
$active-ribbon-wrapper-color: #00D27A;

.btn-ribbon-group {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.btn-ribbon {
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  font-size: 13px;
  color: $text-color;
  text-decoration: none;
  background-color: $ribbon-color;
  border: none;
  cursor: pointer;
  clip-path: polygon(0% 0, calc(100% - 9px) 0, 100% 50%, calc(100% - 9px) 100%, 0% 100%, 9px 50%); /* Create ribbon shape */

  &:hover {
    background-color: darken($ribbon-color, 10%);
  }

  &.active {
    background-color: $active-ribbon-color;
    color: $active-text-color;
  }

  & + .btn-ribbon {
    margin-left: 0px;
  }

  &:first-child {
    clip-path: polygon(0 0, calc(100% - 9px) 0, 100% 50%, calc(100% - 9px) 100%, 0 100%, 0px 50%);
  }

  &:last-child {
    clip-path: polygon(0% 0, calc(100% - 9px) 0, 100% 50%, calc(100% - 9px) 100%, 0% 100%, 9px 50%);
  }
}

.btn-ribbon-wrapper {
  position: relative;
  display: inline-block;
  padding: 0.8px 1px;
  font-size: 14px;
  color: $text-color;
  text-decoration: none;
  background-color: $ribbon-wrapper-color;
  border: none;
  cursor: pointer;
  clip-path: polygon(0% 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 10px 50%); /* Create ribbon shape */
  margin-left: -9px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.active {
    background-color: $active-ribbon-wrapper-color;
    color: $active-text-color;
  }

  &.visible {
    opacity: 1;
  }

  & + .btn-ribbon {
    margin-left: 0px;
  }

  &:first-child {
    clip-path: polygon(0 0, calc(100% - 9px) 0, 100% 50%, calc(100% - 9px) 100%, 0 100%, 0px 50%);
  }

  &:last-child {
    clip-path: polygon(0% 0, calc(100% - 9px) 0, 100% 50%, calc(100% - 9px) 100%, 0% 100%, 9px 50%);
  }
}
